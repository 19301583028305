import clsx from 'clsx';
import Link from 'next/link';

interface IProps {
  title: string;
  href: string | null;
  iconSrc: string;
  classname?: string;
  titleClassname?: string;
}

export const IndustryLink = ({ title, href, iconSrc, classname, titleClassname }: IProps) => {
  return (
    <>
      {href ? (
        <Link
          href={href.includes('nav-header') ? href : `${href}/#nav-header`}
          className={clsx(
            `
            w-full duration-200 flex gap-[8px]
            justify-center items-center py-[6px] px-[10px] mobile:py-[12px] mobile:px-[20px]
            rounded-full border-[1px] border-black-4 hover:border-purple-7 active:border-purple-9
          `,
            {
              [`${classname}`]: classname,
            },
          )}
        >
          <div
            style={{ backgroundImage: `url(${iconSrc})` }}
            className="w-[28px] h-[28px] bg-contain bg-no-repeat"
          />
          <span
            className={clsx('text-black-10 text-base mobile:text-[20px]', {
              [`${titleClassname}`]: titleClassname,
            })}
          >
            {title}
          </span>
        </Link>
      ) : (
        <div
          className={clsx(
            `w-full flex gap-[8px] justify-center 
            items-center py-[6px] px-[10px] mobile:py-[12px] mobile:px-[20px] rounded-full border-[1px] border-black-4
        `,
            {
              [`${classname}`]: classname,
            },
          )}
        >
          <div
            style={{ backgroundImage: `url(${iconSrc})` }}
            className="w-[28px] h-[28px] bg-contain bg-no-repeat"
          />
          <span
            className={clsx('text-black-10 text-base mobile:text-[20px]', {
              [`${titleClassname}`]: titleClassname,
            })}
          >
            {title}
          </span>
        </div>
      )}
    </>
  );
};
