export const HOLE_SIDE_SIZE = 55.43;
export const BORDER_SIZE = HOLE_SIDE_SIZE / 2;

export const PUZZLE_PIECES = [
  // 1
  {
    zIndex: 1,
    animationDelay: 2.8,
    holeOffset: {
      x: BORDER_SIZE,
      y: BORDER_SIZE,
    },
    containerOffset: {
      x: 170.6,
      y: 95,
    },
  },
  // 2
  {
    zIndex: 1,
    animationDelay: 1.7,
    holeOffset: {
      x: BORDER_SIZE + HOLE_SIDE_SIZE,
      y: BORDER_SIZE + HOLE_SIDE_SIZE * 2,
    },
    containerOffset: {
      x: 210,
      y: 172,
    },
  },
  // 3
  {
    zIndex: 1,
    animationDelay: 3.6,
    holeOffset: {
      x: BORDER_SIZE + HOLE_SIDE_SIZE,
      y: BORDER_SIZE + HOLE_SIDE_SIZE * 5,
    },
    containerOffset: {
      x: 209,
      y: 315,
    },
  },
  // 4
  {
    zIndex: 1,
    animationDelay: 1.3,
    holeOffset: {
      x: BORDER_SIZE + HOLE_SIDE_SIZE * 3,
      y: BORDER_SIZE + HOLE_SIDE_SIZE * 2,
    },
    containerOffset: {
      x: 287.3,
      y: 134,
    },
  },
  // 5
  {
    zIndex: 1,
    animationDelay: 2.8,
    holeOffset: {
      x: BORDER_SIZE + HOLE_SIDE_SIZE * 3,
      y: BORDER_SIZE + HOLE_SIDE_SIZE * 3,
    },
    containerOffset: {
      x: 287.3,
      y: 180,
    },
  },
  // 6
  {
    zIndex: 1,
    animationDelay: 2.8,
    holeOffset: {
      x: BORDER_SIZE + HOLE_SIDE_SIZE * 2,
      y: BORDER_SIZE + HOLE_SIDE_SIZE * 5,
    },
    containerOffset: {
      x: 247.5,
      y: 295.1,
    },
  },
  // 7
  {
    zIndex: 1,
    animationDelay: 2.1,
    holeOffset: {
      x: BORDER_SIZE + HOLE_SIDE_SIZE * 4,
      y: BORDER_SIZE + HOLE_SIDE_SIZE * 5,
    },
    containerOffset: {
      x: 327.5,
      y: 256,
    },
  },
  // 8
  {
    zIndex: 1,
    animationDelay: 3.6,
    holeOffset: {
      x: BORDER_SIZE + HOLE_SIDE_SIZE * 5,
      y: BORDER_SIZE + HOLE_SIDE_SIZE * 6,
    },
    containerOffset: {
      x: 364.5,
      y: 284,
    },
  },
  // 9
  {
    zIndex: 10,
    animationDelay: 3.6,
    holeOffset: {
      x: BORDER_SIZE + HOLE_SIDE_SIZE * 6,
      y: BORDER_SIZE + HOLE_SIDE_SIZE * 3,
    },
    containerOffset: {
      x: 405.6,
      y: 119.1,
    },
  },
  // 10
  {
    zIndex: 1,
    animationDelay: 4.1,
    holeOffset: {
      x: BORDER_SIZE + HOLE_SIDE_SIZE * 6,
      y: BORDER_SIZE + HOLE_SIDE_SIZE * 2,
    },
    containerOffset: {
      x: 404.6,
      y: 74,
    },
  },
  // 11
  {
    zIndex: 1,
    animationDelay: 2.1,
    holeOffset: {
      x: BORDER_SIZE + HOLE_SIDE_SIZE * 3,
      y: BORDER_SIZE,
    },
    containerOffset: {
      x: 288.9,
      y: 36,
    },
  },
];
