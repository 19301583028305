'use client';

import Image from 'next/image';
import { TouchEvent, useEffect, useRef } from 'react';
import { useState } from 'react';
import { useIsInViewport } from '@/hooks/useIsInViewport';
import './index.scss';

interface IProps {
  beforeImage: string;
  afterImage: string;
}

export const BeforeAfterSlider = ({ beforeImage, afterImage }: IProps) => {
  const [sliderPosition, setSliderPosition] = useState(50);
  const [isDragging, setIsDragging] = useState(false);

  const [isAnimationCalled, setAnimationCall] = useState(false);
  const [activeAnimation, setActiveAnimation] = useState(false);
  const imageRef = useRef(null);

  const isInViewPort = useIsInViewport(imageRef);

  useEffect(() => {
    if (isInViewPort && !isAnimationCalled) {
      setAnimationCall(true);
      setActiveAnimation(true);
    }
  }, [isAnimationCalled, isInViewPort]);

  const handleMove = (clientX: number, rect: DOMRect) => {
    const x = Math.max(0, Math.min(clientX - rect.left, rect.width));
    const percent = Math.max(0, Math.min((x / rect.width) * 100, 100));
    setSliderPosition(percent);
  };

  const handleMouseMove = (event: any) => {
    if (!isDragging) return;
    const rect = event.currentTarget.getBoundingClientRect();
    handleMove(event.clientX, rect);
  };

  const handleTouchMove = (event: TouchEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    const rect = event.currentTarget.getBoundingClientRect();
    if (event.touches.length > 0) {
      const touch = event.touches[0];
      handleMove(touch.clientX, rect);
    }
  };

  const handleInteractionStart = () => setIsDragging(true);
  const handleInteractionEnd = () => setIsDragging(false);

  return (
    <div
      className="h-full w-full"
      onMouseUp={handleInteractionEnd}
      onTouchEnd={handleInteractionEnd}
    >
      <div
        className={`
          relative w-full h-full flex justify-center items-center max-w-[400px] aspect-[70/45] m-auto
          overflow-hidden select-none transition-all ease-out duration-700
          ${activeAnimation ? 'flashLoad' : ''}
        `}
        ref={imageRef}
        onMouseMove={handleMouseMove}
        onTouchMove={handleTouchMove}
        onMouseDown={handleInteractionStart}
        onTouchStart={handleInteractionStart}
      >
        <div
          className={`absolute ${activeAnimation ? 'slideMoveBeforeAnimation' : ''}`}
          style={{ clipPath: `inset(0 0 0 ${sliderPosition}%)` }}
        >
          <Image
            alt="Before slider"
            src={beforeImage}
            width={400}
            height={400}
          />
        </div>

        <div
          className={`absolute left-0 right-0 overflow-hidden select-none
            w-full max-w-[400px] aspect-[70/45] m-auto ${activeAnimation ? 'slideMoveAfterAnimation' : ''}`}
          style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
        >
          <Image
            alt="After slider"
            src={afterImage}
            width={400}
            height={400}
          />
        </div>
        <div
          className={`absolute top-0 bottom-0 w-[3px] bg-purple-7 shadow-pink-outline cursor-ew-resize ${
            activeAnimation ? 'slideMoveControlAnimation' : ''
          }`}
          style={{
            left: `calc(${sliderPosition}% - 1px)`,
          }}
        />
      </div>
    </div>
  );
};
