import { ICoordinates } from '@/components/AnimatedPuzzle/interfaces';

interface IProps {
  holeOffset: ICoordinates;
  width: number;
  height: number;
  animationDelay: number;
}

export const Hole = ({ holeOffset, width, height, animationDelay }: IProps) => (
  <div
    className="absolute bg-black-13 opacity-100 hole-fade-out"
    style={{
      width: `${width}px`,
      height: `${height}px`,
      left: `${holeOffset.x}px`,
      bottom: `${holeOffset.y}px`,
      animationDelay: `${animationDelay - 1.5 * 0.6 + 0.9}s`,
    }}
  />
);
