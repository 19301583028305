'use client';
import { IServiceCardProps, ServiceCard } from '@/components/Common/Services/Card';
import { Lens } from '@/components/Common/Services/Lens';
import { useScreenSize } from '@/hooks/useScreenSize';
import { BREAKPOINTS } from '@/constants/breakpoints';
import clsx from 'clsx';
import { AnimationBlock } from '@/components/AnimationBlock';
import { useRef } from 'react';

interface IProps {
  data: {
    title: string;
    cards: Omit<IServiceCardProps, 'index' | 'isTablet'>[];
  };
  withLens?: boolean;
  animated?: boolean;
  className: string;
  onButtonClick: () => void;
}

export const Services = ({ data, withLens, className, animated, onButtonClick }: IProps) => {
  const { width } = useScreenSize();

  const titleRef = useRef(null);

  const titleContent = (
    <h3
      className="intro-pre-title mb-xs lg:!mb-[101px] !text-green-7"
      ref={titleRef}
    >
      {data.title}
    </h3>
  );

  return (
    <section
      className={clsx('', {
        [`${className}`]: className,
      })}
    >
      {withLens && <Lens />}

      <div className="container mx-auto pb-[40px] lg:pb-[240px] z-[1] relative lg:px-[64px] xl-plus:px-[32px]">
        {animated ? (
          <AnimationBlock
            id="service-title-anim"
            blockRef={titleRef}
          >
            {titleContent}
          </AnimationBlock>
        ) : (
          titleContent
        )}
        {data.cards.map((card, cardIndex) => {
          return (
            <ServiceCard
              key={card.id}
              {...card}
              index={cardIndex}
              isTablet={width < BREAKPOINTS['lg']}
              onButtonClick={onButtonClick}
            />
          );
        })}
      </div>
    </section>
  );
};
