import Image from 'next/image';
import { ICoordinates } from '@/components/AnimatedPuzzle/interfaces';
interface IProps {
  holeOffset: ICoordinates;
  containerOffset: ICoordinates;
  animationDelay: number;
  zIndex: number;
}

export const Cube = ({ containerOffset, holeOffset, animationDelay, zIndex }: IProps) => {
  return (
    <div
      style={{
        zIndex: zIndex,
        position: 'absolute',
        left: `${containerOffset.x}px`,
        bottom: `${containerOffset.y}px`,
      }}
    >
      <div
        className="cube-wrapper"
        style={{ animationDelay: `${animationDelay}s` }}
      >
        <div
          className="
            absolute w-[558.46px] h-[109.04px]
            top-[123px] left-[-510px]
            rotate-[-26deg] opacity-80
            plume-fade-out
          "
          style={{
            backgroundImage: 'linear-gradient(83.12deg, rgba(12, 17, 29, 0) 24.29%, rgba(9, 14, 27, 0.25) 99.89%)',
            animationDelay: `${animationDelay}s`,
          }}
        ></div>
        <div className="cube">
          <div
            className="side left"
            style={{ animationDelay: `${animationDelay}s` }}
          ></div>
          <div
            className="side front shadow-fade-out"
            style={{ animationDelay: `${animationDelay}s` }}
          >
            <Image
              className="absolute"
              style={{
                maxWidth: 'unset',
                left: `-${holeOffset.x}px`,
                bottom: `-${holeOffset.y}px`,
              }}
              src="/images/main/dashboard-puzzle.webp"
              alt="Chart"
              width="578"
              height="490"
              priority
            />
          </div>
          <div
            className="side top"
            style={{ animationDelay: `${animationDelay}s` }}
          ></div>
        </div>
      </div>
    </div>
  );
};
